import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useImageHandler = () => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  const authenticateImageServer = useCallback(async () => {
    try {
      const url = `${window.env.REACT_APP_SERVER_IMAGE}login`;
      const authResponse = await axios.post(url, {
        username: window.env.REACT_APP_USERNAME,
        password: window.env.REACT_APP_PASSWORD,
      });
      if (authResponse.data && authResponse.data.access_token) {
        setToken(authResponse.data.access_token);
      } else {
        console.error('Authentication response is missing the token:', authResponse.data);
        setError('Authentication failed');
      }
    } catch (error) {
      console.error('Error authenticating with image server:', error);
      setError('Error authenticating with image server');
    }
  }, []);

  useEffect(() => {
    authenticateImageServer();
  }, [authenticateImageServer]);

  const fetchImageUrl = useCallback(async (filename) => {
    try {
      if (!token) {
        console.error('Token is empty. Authentication may have failed.');
        setError('Token is empty. Authentication may have failed.');
        return null;
      }

      const imageUrl = filename;
      
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const imageUrlObject = URL.createObjectURL(response.data);
      return imageUrlObject;
    } catch (error) {
      console.error('Error fetching image:', error);
      setError('Error fetching image');
      return null;
    }
  }, [token]);

  return {
    fetchImageUrl,
    error,
  };
};

export default useImageHandler;
