import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderConfirmationModal.css';

const OrderConfirmationModal = ({ orderNumber }) => {
    const navigate = useNavigate();

    const handleViewOrder = () => {
        navigate(`/${orderNumber}`);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Votre commande a été validée avec succès!</h2>
                <p>Votre Numéro de commande est: {orderNumber}</p>
                <button
                    className="modal-content-button"
                    onClick={handleViewOrder}
                >
                    Voir l'état de la commande
                </button>
            </div>
        </div>
    );
};

export default OrderConfirmationModal;
