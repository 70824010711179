import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Categorys.css';
import axios from 'axios';
import ImageHandler from '../ImageHandler/ImageHandler';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 
import headerLogo from './header.png'; 

const Categorys = ({ onSelectCategory }) => {
  const { fetchImageUrl, loading } = ImageHandler();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const hasAutoSelected = useRef(false);
  const categoriesRef = useRef(null); 

  const fetchCategories = useCallback(async (storeId) => {
    try {
      const baseUrl = window.env.REACT_APP_BASE_URL;
      if (!baseUrl) {
        console.error('REACT_APP_BASE_URL is not defined');
        return;
      }

      const url = `${baseUrl.replace(/\/$/, '')}/category/?store_id=${storeId}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        const categoriesWithImages = await Promise.all(
          response.data.categories.map(async (category) => {
            if (category.image_url) {
              const imageUrl = await fetchImageUrl(category.image_url);
              return { ...category, image_url: imageUrl };
            }
            return category;
          })
        );
        setCategories(categoriesWithImages);

        if (categoriesWithImages.length > 0 && !hasAutoSelected.current) {
          const firstCategory = categoriesWithImages[0];
          setSelectedCategory(firstCategory.id);
          onSelectCategory(firstCategory.id, firstCategory.name);
          hasAutoSelected.current = true;
        }
      } else {
        console.error('Failed to fetch categories', response);
      }
    } catch (error) {
      console.error('Erreur lors du chargement des catégories :', error);
    }
  }, [fetchImageUrl, onSelectCategory]);

  useEffect(() => {
    const fetchData = async () => {
      const storeId = window.env.REACT_APP_STORE_ID;
      if (storeId) {
        await fetchCategories(storeId);
      } else {
        console.error('No storeId found');
      }
    };

    if (!loading) {
      fetchData();
    }
  }, [fetchCategories, loading]);

  const handleCategoryClick = (categoryId, categoryName) => {
    setSelectedCategory(categoryId);
    onSelectCategory(categoryId, categoryName);
  };

  const scrollCategories = (direction) => {
    if (categoriesRef.current) {
      const { scrollLeft, clientWidth } = categoriesRef.current;
      const scrollAmount = clientWidth * 0.8; // Adjust scroll amount as needed
      if (direction === 'left') {
        categoriesRef.current.scrollTo({
          left: scrollLeft - scrollAmount,
          behavior: 'smooth',
        });
      } else {
        categoriesRef.current.scrollTo({
          left: scrollLeft + scrollAmount,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div className="categories-container">
      <div className="header-logo-container">
        <img src={headerLogo} alt="Header Logo" className="header-logo" />
        <div className="text-overlay">ROYAL NAN</div>
      </div>
      <h2>Découvrez notre menu</h2>
      <p className='explore-menu-test'>
        Laissez-vous tenter par nos ingrédients frais et de qualité. Commandez dès maintenant pour une expérience gustative inoubliable !
      </p>

      <div className="categories-wrapper">
        <FaChevronLeft className="scroll-icon left" onClick={() => scrollCategories('left')} />
        
        <div className="categories-scroll" ref={categoriesRef}>
          {categories.map(category => (
            <div
              key={category.id}
              className={`category-item ${selectedCategory === category.id ? 'selected' : ''}`}
              onClick={() => handleCategoryClick(category.id, category.name)}
            >
              <div className="image-container">
                {category.image_url ? (
                  <img src={category.image_url} alt={category.name} />
                ) : (
                  <p>Pas d'image</p>
                )}
              </div>
              <p>{category.name}</p>
            </div>
          ))}
        </div>

        <FaChevronRight className="scroll-icon right" onClick={() => scrollCategories('right')} />
      </div>
    </div>
  );
};

export default Categorys;
