import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ViewOrder.css';
import { DateTime } from 'luxon';
import ImageHandler from '../ImageHandler/ImageHandler';
import { requestFirebaseNotificationPermission, onMessageListener } from '../../firebase';

// Fonction qui renvoie une classe CSS basée sur le statut de la commande
const getStatusClass = (status) => {
    switch (status) {
        case 'Reçue':
            return 'recue';
        case 'En cours':
            return 'en-cours';
        case 'Terminée':
            return 'terminee';
        default:
            return '';
    }
};

const ViewOrder = () => {
    const { orderNumber } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { fetchImageUrl } = ImageHandler();
    const storeId = window.env.REACT_APP_STORE_ID;

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const statusResponse = await fetch(`${window.env.REACT_APP_BASE_URL}get_status_order?order_number=${orderNumber}`);
                const statusData = await statusResponse.json();
                if (!statusResponse.ok) throw new Error(statusData.error || 'Erreur lors de la récupération du statut de la commande');

                const orderResponse = await fetch(`${window.env.REACT_APP_BASE_URL}orders/${orderNumber}`);
                const orderData = await orderResponse.json();
                if (!orderResponse.ok) throw new Error(`Commande non trouvée pour le numéro : ${orderNumber}`);

                const menusWithImages = await Promise.all(orderData.menus.map(async (menu) => {
                    const imageUrlObject = await fetchImageUrl(menu.menu_image_url);
                    return {
                        ...menu,
                        menu_image_url: imageUrlObject || menu.menu_image_url,
                    };
                }));

                const totalPrice = menusWithImages.reduce((acc, menu) => acc + (parseFloat(menu.menu_price) + menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0) + menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0)) * menu.quantity, 0);

                setOrderDetails({
                    ...orderData,
                    menus: menusWithImages,
                    status: statusData.status,
                    totalPrice: totalPrice.toFixed(2)
                });
            } catch (error) {
                setError(error.message || 'Erreur interne');
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderNumber, fetchImageUrl]);

    useEffect(() => {
        if (!storeId) {
            console.error('storeId is not found in localStorage');
            return;
        }

        const setupFirebaseNotifications = async () => {
            try {
                const clientId = localStorage.getItem('client_id');  // Supposons que le client_id est stocké dans localStorage
        
                if (!clientId) {
                    console.error('Client ID is missing');
                    return;
                }
        
                const firebaseToken = await requestFirebaseNotificationPermission(clientId);  // Passer le client_id ici
                console.log('Firebase token:', firebaseToken);
                setupNotificationListener();
            } catch (error) {
                console.error('Erreur d\'autorisation de Firebase:', error);
            }
        };

        setupFirebaseNotifications();

        const socket = new WebSocket(`${window.env.REACT_APP_BASE_URL_WSS}ws/received/${storeId}/`);
        socket.onopen = () => console.log('WebSocket connection established');
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'status_update_notification' && data.order_number === orderNumber) {
                setOrderDetails(prevDetails => ({
                    ...prevDetails,
                    status: data.new_status,
                }));
            }
        };
        socket.onclose = () => console.log('WebSocket connection closed');
        socket.onerror = (error) => console.error('WebSocket error:', error);

        return () => {
            socket.close();
        };
    }, [orderNumber, storeId]);

    const setupNotificationListener = () => {
        onMessageListener()
            .then(payload => {
                console.log('Notification reçue: ', payload);
                new Notification(payload.notification.title, {
                    body: payload.notification.body
                    //icon: '/royalnaan192.png',
                    //badge: '/royalnaan96.png'
                });
            })
            .catch(err => console.error('Erreur de réception de notification: ', err));
    };

    if (loading) return <p>Chargement des détails de la commande...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="order-details">
            <div className="order-details-container">
                <h1 className="order-header">Ma Commande #{orderDetails.order_number}</h1>
                
                <div className="order-info">
                    <div className="client-info">
                        <h2>Client</h2>
                        <p><strong>Nom:</strong> {orderDetails.client.first_name} {orderDetails.client.last_name}</p>
                        <p><strong>Téléphone:</strong> {orderDetails.client.phone}</p>
                        {!orderDetails.is_planned && <p><strong>Adresse:</strong> {orderDetails.client.address}</p>}
                    </div>

                    <div className="order-status-container">
                        <h2>Status</h2>
                        <div className="status-container">
                            <div className={`status-bubble ${getStatusClass(orderDetails.status)}`}></div>
                            <span className="status-text">
                                {orderDetails.status === 'En cours' ? 'En cours de préparation' :
                                orderDetails.status === 'Terminée' ? 'Prête' :
                                orderDetails.status}
                            </span>
                        </div>
                    </div>

                    <div className="payment-info">
                        <h2>Paiement</h2>
                        <p><strong>Méthode:</strong> {orderDetails.payment_type.name}</p>
                        <p><strong>Total:</strong> {orderDetails.totalPrice}€</p>
                    </div>
                </div>

                <div className="menu-details">
                    <h3>Menus choisis :</h3>
                    <ul>
                        {orderDetails.menus.map((menu) => (
                            <li key={menu.id}>
                                <img src={menu.menu_image_url} alt={menu.menu_name} className="menu-image" />
                                <div className="menu-info">
                                    <strong className="menu-name">{menu.menu_name}</strong>
                                    <span className="menu-quantity">Quantité : {menu.quantity}x</span>
                                    <span className="menu-price">Prix : {(parseFloat(menu.menu_price) + menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0) + menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0))}€</span>
                                    <div className="menu-extras">
                                        {menu.selected_ingredients.length > 0 && (
                                            <p><strong>Ingrédients :</strong> {menu.selected_ingredients.map(ing => ing.name).join(', ')}</p>
                                        )}
                                        {menu.selected_supplements.length > 0 && (
                                            <p><strong>Suppléments :</strong> {menu.selected_supplements.map(sup => sup.name).join(', ')}</p>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="order-date-time">
                    <p><strong>Date:</strong> {orderDetails.date}</p>
                    <p><strong>Heure:</strong> {DateTime.fromFormat(orderDetails.time, "HH:mm:ss").toFormat("HH:mm")}</p>
                </div>
            </div>
        </div>
    );
};

export default ViewOrder;
