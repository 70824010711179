import React from 'react';
import './ConfirmModal.css';

const ConfirmModal = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="modal-overlay">
            <div className="modal">
                <h3>{message}</h3>
                <div className="btn-container">
                    <button className="btn delete" onClick={onConfirm}>Oui</button>
                    <button className="btn cancel" onClick={onCancel}>Non</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
