import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import CheckoutPage from '../../Components/checkout/CheckoutPage';
import { Element } from 'react-scroll';

const Informations = () => {
  return (
    <div>
      <Navbar showCart={false} /> 
      <Element name="header">
        <CheckoutPage />
      </Element>
      <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
};

export default Informations;
