import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Element } from 'react-scroll';
import CartPage from '../../Components/cart/CartPage';

const Cart = () => {
  return (
    <div>
      <Navbar showCart={false} /> 
      <Element name="header">
        <CartPage />
      </Element>
      <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
};

export default Cart;
