import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Categorys from '../../Components/Categorys/Categorys';
import Menu from '../../Components/Menu/Menu';
import Footer from '../../Components/Footer/Footer';
import { Element } from 'react-scroll';


const Menus = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);

  const handleSelectCategory = (categoryId, categoryName) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName(categoryName);
  };

  return (
    <div>
      <Navbar />
      <Element name="header">
        <Categorys onSelectCategory={handleSelectCategory} />
        {selectedCategoryId && (
          <Menu categoryId={selectedCategoryId} categoryName={selectedCategoryName} />
        )}
      </Element>
       <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
};

export default Menus;
