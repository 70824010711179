import React, { useEffect, useCallback, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './CartPage.css';
import { useCart } from '../../CartContext/CartContext';
import ImageHandler from '../ImageHandler/ImageHandler';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import axios from 'axios';

const CartPage = () => {
    const { cartData, removeFromCart, updateCartCount, setCartData } = useCart();
    const sessionKey = sessionStorage.getItem('sessionKey');
    const navigate = useNavigate();
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [deliveryStartPrice, setDeliveryStartPrice] = useState(0);
    const BASE_URL = window.env.REACT_APP_BASE_URL;
    const storeId = window.env.REACT_APP_STORE_ID;
    const { fetchImageUrl } = ImageHandler();
    const [isModalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const jwt = localStorage.getItem('jwt');
    const address = localStorage.getItem('address');

    const fetchCartData = useCallback(async () => {
        if (!sessionKey) {
            console.error('No session key found');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}cart/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Session ${sessionKey}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch cart data');
            }

            const data = await response.json();
            const menusWithImages = await Promise.all(
                data.menus.map(async (menu) => {
                    const imageUrl = menu.menu_image_url ? await fetchImageUrl(menu.menu_image_url) : null;
                    return { ...menu, menu_image_url: imageUrl, image_loaded: !!imageUrl };
                })
            );

            setCartData({ ...data, menus: menusWithImages });
            updateCartCount(menusWithImages.length);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    }, [sessionKey, fetchImageUrl]);

    useEffect(() => {
        const fetchStore = async () => {
            try {
                const url = `${BASE_URL}parameter-store/?store_id=${storeId}`;
                const res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    },
                    withCredentials: true
                });
                setDeliveryPrice(res.data.delivery_price);
                setDeliveryStartPrice(res.data.delivery_start_price);
            } catch (error) {
                console.error('Error fetching store parameters:', error);
            }
        };

        fetchStore();
        fetchCartData();
    }, [sessionKey, fetchImageUrl]); 
    
    const handleDelete = (index) => {
        setItemToDelete(index);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        const index = itemToDelete;
        const menu = cartData.menus[index];
        if (!menu) {
            console.error('Menu at index', index, 'is null or undefined.');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}cart/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Session ${sessionKey}`,
                },
                body: JSON.stringify({
                    store_id: storeId,
                    menu_id: menu.menu_id,
                    selected_ingredients: menu.selected_ingredients.map((ingredient) => ingredient.id),
                    selected_supplements: menu.selected_supplements.map((supplement) => supplement.id),
                    free_choice: menu.free_choice
                }),
            });

            if (response.ok) {
                removeFromCart(index);
                fetchCartData(); // Refetch data after deletion
            } else {
                const data = await response.json();
                throw new Error(data.error || 'Failed to delete cart item');
            }
        } catch (error) {
            console.error('Error deleting cart item:', error);
        } finally {
            setModalOpen(false); 
            setItemToDelete(null); 
        }
    };

    const cancelDelete = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    const handleUpdateQuantity = async (index, newQuantity) => {
        const menu = cartData.menus[index];
        if (!menu) {
            console.error('Menu at index', index, 'is null or undefined.');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}cart/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Session ${sessionKey}`,
                },
                body: JSON.stringify({
                    store_id: storeId,
                    menu_id: menu.menu_id,
                    quantity: newQuantity,
                    selected_ingredients: menu.selected_ingredients.map((ingredient) => ingredient.id),
                    selected_supplements: menu.selected_supplements.map((supplement) => supplement.id),
                    free_choice: menu.free_choice
                }),
            });

            if (response.ok) {
                setCartData(prevCartData => {
                    const updatedMenus = [...prevCartData.menus];
                    updatedMenus[index].quantity = newQuantity;
                    return { ...prevCartData, menus: updatedMenus };
                });
            } else {
                const data = await response.json();
                console.error(`Failed to update quantity for menu with id ${menu.menu_id}. Error:`, data.error);
                throw new Error(data.error || 'Failed to update cart quantity');
            }
        } catch (error) {
            console.error('Error updating cart quantity:', error);
        }
    };

    const calculateTotalPriceForMenu = (menu) => {
        if (!menu || !menu.selected_ingredients) {
            console.error('Menu or selected_ingredients is null or undefined.');
            return 0;
        }
        const ingredientsTotal = menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0);
        const supplementsTotal = menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0);
        return (parseFloat(menu.menu_price) + ingredientsTotal + supplementsTotal) * menu.quantity;
    };

    const calculateTotalPrice = (menus) => {
        if (!menus || !Array.isArray(menus)) {
            console.error('Menus is null or not an array.');
            return 0;
        }
        return menus.reduce((total, menu) => {
            return total + calculateTotalPriceForMenu(menu);
        }, 0).toFixed(2);
    };

    if (!cartData || !Array.isArray(cartData.menus)) {
        return <div>Loading...</div>;
    }

    return (
        <div className='cart-contents'>
            <div className="cart-container">
                        <h1 className="cart-header">Votre Panier</h1>
                        {cartData.menus.length === 0 ? (
                            <div className="empty-cart-message">
                                <p>Il n'y a pas de commande dans le panier.</p>
                            </div>
                        ) : (
                            <>
                                {cartData.menus.map((menu, index) => (
                                    <div key={index} className="cart-item">
                                        {menu && (
                                            <>
                                                <div className="cart-item-image">
                                                    {menu.image_loaded ? (
                                                        <img
                                                            src={menu.menu_image_url}
                                                            alt={menu.menu_name}
                                                            className="cart-menu-image"
                                                        />
                                                    ) : (
                                                        <div className="placeholder-image">Pas d'image</div>
                                                    )}
                                                </div>
                                                <div className="cart-item-details">
                                                    <h2>{menu.menu_name}</h2>
                                                    <p>Prix: {menu.menu_price}€</p>
                                                    <p>Quantité: 
                                                        <select
                                                            value={menu.quantity}
                                                            onChange={(e) => handleUpdateQuantity(index, parseInt(e.target.value))}
                                                            className="quantity-select"
                                                        >
                                                            {[...Array(10).keys()].map(i => (
                                                                <option key={i} value={i+1}>{i+1}</option>
                                                            ))}
                                                        </select>
                                                    </p>
                                                    {menu.selected_ingredients && menu.selected_ingredients.length > 0 && (
                                                        <div>
                                                            <h4>Ingrédients sélectionnés :</h4>
                                                            <ul>
                                                                {menu.selected_ingredients.map(ingredient => (
                                                                    <li key={ingredient.id}>{ingredient.name} + {ingredient.price}€</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    {menu.selected_supplements && menu.selected_supplements.length > 0 && (
                                                        <div>
                                                            <h4>Suppléments sélectionnés :</h4>
                                                            <ul>
                                                                {menu.selected_supplements.map(supplement => (
                                                                    <li key={supplement.id}>{supplement.name} + {supplement.price}€</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    {menu.free_choice && menu.free_choice !== '' && (
                                                        <div>
                                                            <h4>Instructions spécifiques :</h4>
                                                            <ul>
                                                                <li>{menu.free_choice}</li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                    <p className="menu-total-price">Prix total : {calculateTotalPriceForMenu(menu).toFixed(2)}€</p>
                                                    
                                                </div>
                                                <FaTrash onClick={() => handleDelete(index)} className="delete-icon" />

                                            </>
                                        )}
                                    </div>
                                ))}
                                
                                <div className="cart-summary">
                                    <p>
                                    {address.length !== 0 ? (
                                        <>
                                            <p className="total-with-delivery">Total de la commande : {calculateTotalPrice(cartData.menus)}€</p>
                                            {deliveryPrice > 0 ? (
                                                <>
                                                    <p className="delivery-fee">Frais de livraison : {deliveryPrice}€ </p><br />
                                                    <h2 className="total-price">
                                                        Total avec livraison : {(parseFloat(calculateTotalPrice(cartData.menus)) + parseFloat(deliveryPrice)).toFixed(2)}€
                                                    </h2>
                                                </>
                                            ) : (
                                                'Livraison gratuite'
                                            )}
                                        </>
                                    ) : (
                                        <h2 className="total-price">
                                            Total de la commande : {calculateTotalPrice(cartData.menus)}€
                                        </h2>
                                    )}
                                    </p>
                                </div>
                                <div className="order-button-container">
                                    <button 
                                        className="order-button" 
                                        onClick={() => navigate('/informations')}
                                        disabled={address.length !== 0 && calculateTotalPrice(cartData.menus) < deliveryStartPrice}
                                    >
                                        Commander
                                    </button>                                   
                                    {address.length !== 0 && calculateTotalPrice(cartData.menus) < deliveryStartPrice && (
                                        <div className="disabled-message">
                                            La livraison est effectuée à partir de {deliveryStartPrice} €
                                        </div>
                                    )}
                                </div>
                            
                            </>
                        )}

                        {isModalOpen && (
                            <ConfirmModal
                                message="Êtes-vous sûr de vouloir supprimer cet article du panier ?"
                                onConfirm={confirmDelete}
                                onCancel={cancelDelete}
                            />
                        )}
                    </div>
        </div>
        
    );
};

export default CartPage;
