import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Menus from './Pages/Menus/Menus';  
import Informations from './Pages/Informations/Informations';
import Commande from './Pages/Commande/Commande';
import Cart from './Pages/Cart/Cart';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menus" element={<Menus />} />  
            <Route path="/cart" element={<Cart />} />
            <Route path="/informations" element={<Informations />} />
            <Route path="/:orderNumber" element={<Commande />} />
        </Routes>
    );
}

export default App;
