import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { Element } from 'react-scroll';

const Home = () => {
  return (
    <div>
      <Navbar showCart={false} /> 
      <Element name="header">
        <Header />
      </Element>
      <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
};

export default Home;
