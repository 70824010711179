import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Element } from 'react-scroll';
import ViewOrder from '../../Components/order/ViewOrder';

const Commande = () => {
  return (
    <div>
      <Navbar showCart={false} /> 
      <Element name="header">
        <ViewOrder />
      </Element>
      <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
};

export default Commande;
