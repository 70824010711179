import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Menu.css';
import ImageHandler from '../ImageHandler/ImageHandler';
import { useCart } from '../../CartContext/CartContext';

const Menu = ({ categoryId, categoryName }) => {
  const { fetchImageUrl, loading } = ImageHandler();
  const { addToCart } = useCart(); // Access addToCart function from context
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [constraints, setConstraints] = useState([]);
  const [sessionKey, setSessionKey] = useState(sessionStorage.getItem('sessionKey') || null);
  const [qtt, setQtt] = useState(1);
  const [freeChoice, setFreeChoice] = useState('');

  useEffect(() => {
    if (!sessionKey) {
      fetchSessionKey();
    }
  }, [sessionKey]);

  useEffect(() => {
    const fetchMenus = async (categoryId) => {
      try {
        const url = `${window.env.REACT_APP_BASE_URL}menus/by_category/${categoryId}/`;
        const response = await axios.get(url);
        const menusWithImages = await Promise.all(response.data.map(async (menu) => {
          if (menu.image_url) {
            const imageUrl = await fetchImageUrl(menu.image_url);
            return { ...menu, image_url: imageUrl };
          }
          return menu;
        }));
        setMenus(menusWithImages);
      } catch (error) {
        console.error('Error fetching menus:', error);
      }
    };

    const fetchData = async () => {
      if (categoryId) {
        await fetchMenus(categoryId);
      }
    };

    if (!loading) {
      fetchData();
    }
  }, [categoryId, fetchImageUrl, loading]);

  const fetchMenuDetails = async (menuId) => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}menu/${menuId}/getAdditionnelProduct/`;
      const response = await axios.get(url);
      if (response.data && response.data.image_url) {
        const imageUrl = await fetchImageUrl(response.data.image_url);
        setSelectedMenu({ ...response.data, image_url: imageUrl });
        setTotalPrice(parseFloat(response.data.price));
      } else {
        setSelectedMenu(response.data);
        setTotalPrice(parseFloat(response.data.price));
      }
    } catch (error) {
      console.error('Error fetching menu details:', error);
    }

    try {
      const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/?menu_id=${menuId}`;
      const res = await axios.get(url, { menu_id: 23 });
      setConstraints(res.data);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const handleMenuClick = async (menu) => {
    if (menu.supplements.length === 0 && menu.ingredients.length === 0) {
      handleAddToCart(menu);
    } else {
      await fetchMenuDetails(menu.id);
      setSelectedSupplements([]);
      setSelectedIngredients([]);
    }
  };

  const closePopup = () => {
    setSelectedMenu(null);
    setSelectedSupplements([]);
    setSelectedIngredients([]);
    setTotalPrice(0);
    setFreeChoice('');
  };

  const handleSupplementChange = (supplement, constraint) => {
    const isSelected = selectedSupplements.includes(supplement);
  
    // Check the current selection for this tag
    const currentTagSelections = selectedSupplements.filter(
      (item) => item.tag_id === supplement.tag_id
    );
  
    // Ensure the required number of selections is met
    if (!isSelected && currentTagSelections.length >= constraint?.max_required) {
      return;
    }

    setSelectedSupplements((prevState) =>
      isSelected
        ? prevState.filter((item) => item !== supplement)
        : [...prevState, supplement]
    );
  
    setTotalPrice((prevPrice) => {
      const updatedPrice = isSelected ? prevPrice - supplement.price : prevPrice + supplement.price;
  
      // Avoid floating-point precision issues by rounding to 2 decimal places
      return Math.round(updatedPrice * 100) / 100;
    });
  };

  const handleIngredientChange = (ingredient, constraint) => {
    const isSelected = selectedIngredients.includes(ingredient);
  
    // Check the current selection for this tag
    const currentTagSelections = selectedIngredients.filter(
      (item) => item.tag_id === ingredient.tag_id
    );
  
    // Ensure the required number of selections is met
    if (!isSelected && currentTagSelections.length >= constraint?.max_required) {
      return;
    }

    setSelectedIngredients((prevState) =>
      isSelected
        ? prevState.filter((item) => item !== ingredient)
        : [...prevState, ingredient]
    );
  
    setTotalPrice((prevPrice) => {
      const updatedPrice = isSelected ? prevPrice - ingredient.price : prevPrice + ingredient.price;
  
      // Avoid floating-point precision issues by rounding to 2 decimal places
      return Math.round(updatedPrice * 100) / 100;
    });
  };

  const fetchSessionKey = () => {
    fetch(`${window.env.REACT_APP_BASE_URL}get_or_create_session`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      sessionStorage.setItem('sessionKey', data.session_key);
      setSessionKey(data.session_key);
    })
    .catch(error => {
      console.error('Error fetching session key:', error);
    });
  };

  const handleAddToCart = (menu) => {
    if (!sessionKey) {
      console.error('Session key not available');
      return;
    }
  
    // Vérifiez si le menu a des ingrédients avant d'essayer d'accéder à ses propriétés
    if (!selectedMenu || !selectedMenu.ingredients) {
      // Si le menu n'a pas d'ingrédients, ajoutez-le directement au panier
      const payload = {
        store_id: window.env.REACT_APP_STORE_ID,
        menu_id: menu.id,
        quantity: 1,
        selected_ingredients: [],
        selected_supplements: [],
        image_url: menu.image_url,
        free_choice: freeChoice
      };
  
      fetch(`${window.env.REACT_APP_BASE_URL}cart/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Session ${sessionKey}`,
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(data => {
          addToCart(); 
          closePopup();
        })
        .catch(error => {
          console.error('Error adding to cart:', error);
        });
      return; // Quittez la fonction après avoir ajouté le menu sans ingrédients
    }
  
    const storeId = window.env.REACT_APP_STORE_ID;
    const ingredientIds = selectedIngredients.map(ingredient => ingredient.id);
    const supplementIds = selectedSupplements.map(supplement => supplement.id);
  
    const isAllConstraintsSatisfied = Object.entries(groupIngrByTag(selectedMenu.ingredients)).every(([tagName, { tag_id }]) => {
      const constraint = constraints.find(constraint => constraint.tag === tag_id);
      const currentTagSelections = selectedIngredients.filter(ingredient => ingredient.tag_id === tag_id);
      if (constraint) {
        if (constraint.choices_required === null && currentTagSelections.length >= constraint.min_required) {
          return true;
        }
        return currentTagSelections.length === constraint.choices_required;
      }
      return currentTagSelections.length === 1; // If no constraint, assume it's supposed to be 1 by default
    });
  
    if (!isAllConstraintsSatisfied) {
      alert('Assurer que vous avez choisi le nombre requis d\'ingrédients!');
      return;
    }
  
    const payload = {
      store_id: storeId,
      menu_id: menu.id,
      quantity: qtt,
      selected_ingredients: ingredientIds,
      selected_supplements: supplementIds,
      image_url: menu.image_url,
      free_choice: freeChoice
    };
  
    fetch(`${window.env.REACT_APP_BASE_URL}cart/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Session ${sessionKey}`,
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      addToCart(); 
      closePopup();
    })
    .catch(error => {
      console.error('Error adding to cart:', error);
    });
  };
  

  const groupIngrByTag = (array) => {
    return array.reduce((acc, ingredient) => {
        const { tag, tag_id } = ingredient;
        if (!acc[tag]) {
            acc[tag] = { tag_id, ingredients: [] };
        }
        acc[tag].ingredients.push(ingredient);
        return acc;
    }, {});
  };

  const groupSuppByTag = (array) => {
    return array.reduce((acc, supplement) => {
        const { tag, tag_id } = supplement;
        if (!acc[tag]) {
            acc[tag] = { tag_id, supplements: [] };
        }
        acc[tag].supplements.push(supplement);
        return acc;
    }, {});
  };

  return (
    <div className="menu-container">
      <h2>{categoryName}</h2>
      <div className="menu-list">
        {menus.length === 0 ? (
          <p>Aucun menu trouvé pour cette catégorie.</p>
        ) : (
          <div className="menu-list">
            {menus.map((menu) => (
              <div key={menu.id} className={`menu-item ${menu.in_stock ? '' : 'out-of-stock'}`}>
                <div className="menu-details">
                  {menu.image_url ? (
                    <img src={menu.image_url} alt={menu.name} />
                  ) : (
                    <p>Pas d'image</p>
                  )}
                  <div className='food'>
                    <h3>{menu.name}</h3>
                    <p>{menu.description}</p>
                    {!menu.in_stock && <p className="out-of-stock-message">Indisponible</p>}
                    <button
                      className={`add-to-cart-button ${menu.in_stock ? '' : 'disabled'}`}
                      disabled={!menu.in_stock}
                      onClick={() => handleMenuClick(menu)}
                    >
                      Ajouter au panier {menu.price} €
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedMenu && (
        <div className="menu-popup-overlay" onClick={closePopup}>
          <div className="menu-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closePopup}>X</button>
            <div className="menu-popup-header">
              {selectedMenu.image_url ? (
                <img src={selectedMenu.image_url} alt={selectedMenu.name} />
              ) : (
                <p>Pas d'image</p>
              )}
              <div>
                <h2>{selectedMenu.name}</h2>
                <p>{selectedMenu.description}</p>
                <p className="price">{selectedMenu.price} €</p>
              </div>
            </div>
            <div className="content">
              <ul>
                {Object.entries(groupIngrByTag(selectedMenu.ingredients)).map(([tagName, { tag_id, ingredients }]) => (
                  <React.Fragment key={tagName}>
                    <div>
                      <h4>{tagName}</h4>
                      {constraints.some(constraint => constraint.tag === tag_id) ? (
                        constraints.map(constraint =>
                          constraint.tag === tag_id && (
                            <div key={constraint.tag}>
                              <p style={{ color: 'gray' }}>
                              {
                                constraint.choices_required
                                ? `Choisissez-en ${constraint.choices_required}.  `
                                : constraint.min_required === 0 || constraint.min_required === constraint.max_required
                                ? `Choisissez-en ${constraint.max_required} max.  `
                                : `Entre ${constraint.min_required} et ${constraint.max_required} max.  `
                              }
                              {(constraint.min_required > 0 || constraint.choices_required > 0) &&
                                <span className='obligatoire'>
                                  Obligatoire
                                </span>
                              }
                              </p>
                              <ul>
                                {ingredients.map((ingredient) => (
                                  <li key={ingredient.id}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={selectedIngredients.includes(ingredient)}
                                        onChange={() => handleIngredientChange(ingredient, constraint)}
                                        name={`ingredient-${tag_id}`}
                                        disabled={
                                          ((
                                            selectedIngredients.filter((item) => item.tag_id === tag_id).length >= 
                                            (constraint?.choices_required || constraint?.max_required)
                                          ) && 
                                          !selectedIngredients.includes(ingredient)) || !ingredient.in_stock
                                        }
                                      />
                                      {ingredient.name} + {ingredient.price}€ 
                                      {!ingredient.in_stock && (
                                        <span style={{ color: 'gray', fontSize: '0.8em', marginLeft:'10px' }}>
                                          Rupture de stock
                                        </span>
                                      )}
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )
                        )
                      ) : (
                        <div>
                          <p style={{ color: 'gray' }}>Choisissez-en 1.  
                          <span className='obligatoire'>
                            Obligatoire
                          </span>
                          </p>
                          <ul>
                            {ingredients.map((ingredient) => (
                              <li key={ingredient.id}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedIngredients.includes(ingredient)}
                                    onChange={() => handleIngredientChange(ingredient)}
                                    name={`${ingredient.tag_id}-${ingredient.id}`}
                                    disabled={
                                      (selectedIngredients.some(
                                        (selected) => selected.tag_id === ingredient.tag_id
                                      ) && !selectedIngredients.includes(ingredient)) || !ingredient.in_stock
                                    }
                                  />
                                  {ingredient.name} + {ingredient.price}€
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </ul>
              <ul>
                {Object.entries(groupSuppByTag(selectedMenu.supplements)).map(([tagName, { tag_id, supplements }]) => (
                  <React.Fragment key={tagName}>
                    <div>
                      <h4>{tagName}</h4>
                      {constraints.some(constraint => constraint.tag === tag_id) ? (
                        constraints.map(constraint =>
                          constraint.tag === tag_id && (
                            <div key={constraint.tag}>
                              <p style={{ color: 'gray' }}>
                              {
                                constraint.min_required === 0 || constraint.min_required === constraint.max_required
                                ? `Choisissez-en ${constraint.max_required} max.`
                                : constraint.choices_required
                                ? `Choisissez-en ${constraint.choices_required}.`
                                : `Entre ${constraint.min_required} et ${constraint.max_required} max.`
                              }
                              </p>
                              <ul>
                                {supplements.map((supplement) => (
                                  <li key={supplement.id}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={selectedSupplements.includes(supplement)}
                                        onChange={() => handleSupplementChange(supplement, constraint)}
                                        name={`supplement-${tag_id}`}
                                        disabled={
                                          ((
                                            selectedSupplements.filter((item) => item.tag_id === tag_id).length >= 
                                            (constraint?.choices_required || constraint?.max_required)
                                          ) && 
                                          !selectedSupplements.includes(supplement) )|| !supplement.in_stock
                                        }
                                      />
                                      {supplement.name} + {supplement.price}€
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )
                        )
                      ) : (
                        <div>
                          <p style={{ color: 'gray' }}>Choisissez-en 1.</p>
                          <ul>
                            {supplements.map((supplement) => (
                              <li key={supplement.id}>
                                <label>
                                  <input
                                    type='checkbox'
                                    checked={selectedSupplements.includes(supplement)}
                                    onChange={() => handleSupplementChange(supplement)}
                                    name={`supplement-${tag_id}`}
                                    disabled={
                                      (selectedSupplements.some(
                                        (selected) => selected.tag_id === supplement.tag_id
                                      ) && !selectedSupplements.includes(supplement)) || !supplement.in_stock
                                    }
                                  />
                                  {supplement.name} + {supplement.price}€
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </ul>
              <ul>
                <h4>Instructions spécifiques</h4>
                <ul>
                  <input
                    type='text'
                    value={freeChoice}
                    onChange={(e) => setFreeChoice(e.target.value)}
                    placeholder="Ajoutez un commentaire."
                    class="styled-input"
                  />
                </ul>
              </ul>
              <ul>
                  <div className="quantity-container">
                    <button onClick={() => setQtt(qtt > 1 ? qtt - 1 : 1)}>-</button>
                    <span>{qtt}</span>
                    <button onClick={() => setQtt(qtt < 10 ? qtt + 1 : 10)}>+</button>
                  </div>
              </ul>
            </div>
            <button className="add-to-cart-button center-button" onClick={() => handleAddToCart(selectedMenu)}>
              Ajouter au panier {totalPrice.toFixed(2)} €
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
