function isIOS() {
    if (navigator.userAgentData) {
        // Vérifie si le device est un iPhone, iPad, ou iPod avec userAgentData
        return navigator.userAgentData.brands.some(brand => brand.brand === 'Apple') && 
               navigator.userAgentData.mobile;
    } else {
        // Fallback: vérification avec userAgent
        return /iPad|iPhone|iPod/.test(navigator.userAgent) || 
               (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }
}

if ('serviceWorker' in navigator && !isIOS()) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
        console.log('Service Worker registered with scope:', registration.scope);
    }).catch(function(err) {
        console.log('Service Worker registration failed: ', err);
    });
} else {
    console.log('Service Worker not registered on iOS or unsupported browser');
}
