import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { CartProvider } from './CartContext/CartContext';
import { AddressProvider } from './AddressContext/AddressContext'; 
import { BrowserRouter } from 'react-router-dom';
import './serviceWorkerRegistration.js';  
ReactDOM.render(
  <BrowserRouter>
    <CartProvider>
      <AddressProvider> 
        <App />
      </AddressProvider>
    </CartProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
