import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    // Initialiser le nombre d'articles du panier avec les données de sessionStorage
    const [cartCount, setCartCount] = useState(() => {
        const savedCartCount = sessionStorage.getItem('cartCount');
        return savedCartCount ? parseInt(savedCartCount, 10) : 0;
    });

    // Initialiser les données du panier avec les données de sessionStorage
    const [cartData, setCartDataState] = useState(() => {
        const savedCartData = sessionStorage.getItem('cartData');
        return savedCartData ? JSON.parse(savedCartData) : { menus: [] };
    });

    // Utiliser useEffect pour sauvegarder les données du panier dans sessionStorage
    useEffect(() => {
        sessionStorage.setItem('cartCount', cartCount);
    }, [cartCount]);

    useEffect(() => {
        sessionStorage.setItem('cartData', JSON.stringify(cartData));
    }, [cartData]);

    // Fonction pour ajouter un article au panier
    const addToCart = (menu) => {
        setCartDataState((prevData) => {
            const updatedMenus = [...prevData.menus, menu];
            const updatedCartData = { ...prevData, menus: updatedMenus };
            setCartCount(updatedMenus.length);
            sessionStorage.setItem('cartData', JSON.stringify(updatedCartData));
            return updatedCartData;
        });
    };

    // Fonction pour supprimer un article du panier
    const removeFromCart = (index) => {
        setCartDataState((prevData) => {
            const newMenus = prevData.menus.filter((_, i) => i !== index);
            const updatedCartData = { ...prevData, menus: newMenus };
            setCartCount(newMenus.length);
            sessionStorage.setItem('cartData', JSON.stringify(updatedCartData));
            return updatedCartData;
        });
    };

    // Fonction pour mettre à jour le nombre d'articles du panier
    const updateCartCount = (count) => {
        setCartCount(count);
    };

    // Fonction pour définir les données du panier
    const setCartData = (data) => {
        setCartDataState(data);
        setCartCount(data.menus.length);
        sessionStorage.setItem('cartData', JSON.stringify(data));
    };

    return (
        <CartContext.Provider
            value={{ cartCount, cartData, addToCart, removeFromCart, updateCartCount, setCartData }}
        >
            {children}
        </CartContext.Provider>
    );
};
