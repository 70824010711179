import React, { useState, useEffect } from 'react';
import './CheckoutPage.css';
import { useCart } from '../../CartContext/CartContext';
import OrderConfirmationModal from '../../OrderConfirmationModal/OrderConfirmationModal';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { DateTime } from 'luxon';
import axios from 'axios';
import ImageHandler from '../ImageHandler/ImageHandler'; 

const CheckoutPage = () => {
    const { cartData, updateCartCount } = useCart();
    const address = localStorage.getItem('address');
    const [customerInfo, setCustomerInfo] = useState({
        prenom: '',
        nom: '',
        phone: '',
        email: '',
        address: ''
    });
    const [paymentMethod, setPaymentMethod] = useState('');
    const [orderValidated, setOrderValidated] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [orderNumber, setOrderNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const sessionKey = sessionStorage.getItem('sessionKey');
    const { setCartData } = useCart(); 
    const { fetchImageUrl } = ImageHandler();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${window.env.REACT_APP_BASE_URL}cart/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Session ${sessionKey}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch cart data');
                }

                const data = await response.json();
                // Fetch menu images
                const menusWithImages = await Promise.all(
                    data.menus.map(async (menu) => {
                        const imageUrl = menu.menu_image_url ? await fetchImageUrl(menu.menu_image_url) : null;
                        return { ...menu, menu_image_url: imageUrl, image_loaded: !!imageUrl };
                    })
                );

                setCartData({ ...data, menus: menusWithImages });
                updateCartCount(menusWithImages.length);

                const parameterResponse = await axios.get(`${window.env.REACT_APP_BASE_URL}parameter-store/?store_id=${window.env.REACT_APP_STORE_ID}`);
                setDeliveryFee(parameterResponse.data.delivery_price);

            } catch (error) {
                console.error('Error fetching cart data:', error);
                setErrorMessage('Erreur lors de la récupération des données du panier.');
            }
        };

        fetchData();
    }, [sessionKey, fetchImageUrl]);

    useEffect(() => {
        setCustomerInfo(prevState => ({
            ...prevState,
            address: address || prevState.address
        }));
    }, [address]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleOpenConfirmModal = () => {
        setShowConfirmModal(true);
    };

    const validateForm = () => {
        let isValid = true;
        setErrorMessage('');

        if (!customerInfo.prenom.trim()) {
            setErrorMessage('Veuillez saisir votre prénom.');
            isValid = false;
        }
        if (!customerInfo.phone.trim()) {
            setErrorMessage('Veuillez saisir votre numéro de téléphone.');
            isValid = false;
        } 
        if (!paymentMethod) {
            setErrorMessage('Veuillez sélectionner un mode de paiement.');
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async () => {
        try {
            let isValid = validateForm();
            if (!isValid) {
                return;
            }
    
            setErrorMessage('');
    
            const storeParamResponse = await fetch(`${window.env.REACT_APP_BASE_URL}parameter-store/?store_id=${window.env.REACT_APP_STORE_ID}`);
            if (!storeParamResponse.ok) {
                throw new Error('Failed to fetch store parameters');
            }
    
            const storeParamData = await storeParamResponse.json();
            const lastOrderDeadline = DateTime.fromFormat(storeParamData.last_order_deadline, 'HH:mm:ss');
            const currentTime = DateTime.local();
    
            if (currentTime >= lastOrderDeadline) {
                setErrorMessage(`Vous pouvez passer une commande jusqu'à ${lastOrderDeadline.toFormat('HH:mm')}. Veuillez réessayer demain.`);
                return;
            }
    
            const clientData = {
                first_name: customerInfo.prenom || '',  
                last_name: customerInfo.nom || '',
                phone: customerInfo.phone,
                email: customerInfo.email,  
                address: customerInfo.address || '',
                store: window.env.REACT_APP_STORE_ID
            };
    
            const createClientResponse = await fetch(`${window.env.REACT_APP_BASE_URL}create-client/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(clientData),
            });
    
            if (!createClientResponse.ok) {
                throw new Error('Failed to create client');
            }
    
            const createClientData = await createClientResponse.json();
            const clientId = createClientData.client_id;
            localStorage.setItem('client_id', clientId);
            const validateOrderResponse = await fetch(`${window.env.REACT_APP_BASE_URL}validate-order/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: clientId,
                    cart_id: cartData.id,
                    payment_type: paymentMethod.toLowerCase(),
                    time: localStorage.getItem('take_away_time')
                }),
            });
    
            if (!validateOrderResponse.ok) {
                throw new Error('Failed to validate order');
            }
    
            const validateOrderData = await validateOrderResponse.json();
            setOrderId(validateOrderData.order.id);
            setOrderNumber(validateOrderData.order.order_number); 
            setOrderValidated(true);
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Erreur lors de la validation de la commande. Veuillez réessayer plus tard.');
        }
    };
    
    const calculateTotalPrice = () => {
        if (!cartData || !cartData.menus) return 0;
        return cartData.menus.reduce((total, menu) => {
            const ingredientsTotal = menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0);
            const supplementsTotal = menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0);
            return total + (parseFloat(menu.menu_price) + ingredientsTotal + supplementsTotal) * menu.quantity;
        }, 0).toFixed(2);
    };

    const calculateTotalWithDelivery = () => {
        return (parseFloat(calculateTotalPrice()) + deliveryFee).toFixed(2);
    };

    const formatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\s/g, '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4');
    };

    const handlePhoneKeyDown = (e) => {
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
            'Home', 'End', 'Tab'
        ];

        if ((e.ctrlKey || e.metaKey) && 
            (e.key === 'c' || e.key === 'v' || e.key === 'x')) {
            return;
        }

        if (!/[\d\b]/.test(e.key) && !allowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleConfirmOrder = () => {
        setShowConfirmModal(false);
        handleSubmit();
    };

    const handleCancelOrder = () => {
        setShowConfirmModal(false);
    };

    return (
        <div className="checkout">
            <div className="checkout-contents">
                        {orderValidated && (
                            <OrderConfirmationModal orderNumber={orderNumber} />
                        )}

                        <h2>Informations de livraison</h2>
                        {errorMessage && (
                            <div className="error-message" role="alert">{errorMessage}</div>
                        )}
                        <div className="form-contents">
                            <div className="input-contents">
                                <div className="name-contents">
                                    <input
                                        type="text"
                                        name="prenom"
                                        placeholder="Prénom (Obligatoire)"
                                        value={customerInfo.prenom}
                                        onChange={handleChange}
                                        aria-label="Prénom"
                                    />
                                    <input
                                        type="text"
                                        name="nom"
                                        placeholder="Nom (Facultatif)"
                                        value={customerInfo.nom}
                                        onChange={handleChange}
                                        aria-label="Nom"
                                    />
                                </div>
                                <div className="phone-input-contents">
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder="Numéro de téléphone (Obligatoire)"
                                        value={formatPhoneNumber(customerInfo.phone)}
                                        onChange={handleChange}
                                        onKeyDown={handlePhoneKeyDown}
                                        aria-label="Numéro de téléphone"
                                    />
                                </div>
                                <div className="email-input-contents">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email (Facultatif)"
                                        value={customerInfo.email}
                                        onChange={handleChange}
                                        aria-label="Email"
                                    />
                                </div>
                                <div className="address-input-contents">
                                    { customerInfo.address !== '' &&
                                        <input
                                        type="text"
                                        name="address"
                                        placeholder={customerInfo.address}
                                        value={customerInfo.address}
                                        disabled
                                    />}
                                </div>
                            </div>
                        </div>
                        <h3>Vos commandes</h3>
                        <div className="checkout-details">
                            <ul>
                                {cartData.menus.map((menu) => (
                                    <li key={menu.id} className="checkout-item">
                                        {menu.menu_image_url ? (
                                                        <img src={menu.menu_image_url} alt={menu.menu_name} className="checkout-image" />
                                                    ) : (
                                                        <div className="no-image">Pas d'image</div>
                                                    )}
                                        <div className="checkout-info">
                                            <strong className="checkout-name">{menu.menu_name}</strong>
                                            <span className="checkout-quantity">Quantité : {menu.quantity}x</span>
                                            <span className="checkout-price">Prix : {(parseFloat(menu.menu_price) + menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0) + menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0))}€</span>
                                            <div className="checkout-extras">
                                                {menu.selected_ingredients.length > 0 && (
                                                    <p><strong>Ingrédients :</strong> {menu.selected_ingredients.map(ing => ing.name).join(', ')}</p>
                                                )}
                                                {menu.selected_supplements.length > 0 && (
                                                    <p><strong>Suppléments :</strong> {menu.selected_supplements.map(sup => sup.name).join(', ')}</p>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        </div>
                        <div className="total-contents">
                            {address.length !== 0 ? (
                                    <>
                                        <p className="total-with-delivery">Total de la commande : {calculateTotalPrice()}€</p>
                                        {deliveryFee > 0 ? (
                                            <p className="delivery-fee">Frais de livraison : {deliveryFee.toFixed(2)}€</p>
                                        ) : (
                                            <p>Livraison gratuite</p>
                                        )}
                                        <p className="total-price">Total avec livraison: {calculateTotalWithDelivery()}€</p>
                                    </>
                                ) : (
                                    <p className="total-price-with-delivery">Total de la commande : {calculateTotalPrice()}€</p>
                                )}
                        </div>

                        <div className="payment-contents">
                            <div className="payment-method">
                                <label>
                                    <input
                                        type="radio"
                                        value="Espece"
                                        checked={paymentMethod === 'Espece'}
                                        onChange={handlePaymentChange}
                                        aria-label="Paiement en espèces"
                                    />
                                    Espèces
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Carte"
                                        checked={paymentMethod === 'Carte'}
                                        onChange={handlePaymentChange}
                                        aria-label="Paiement par carte"
                                    />
                                    Carte
                                </label>
                            </div>
                            <div className="validate-button-contents">
                                <button className="validate-button" onClick={handleOpenConfirmModal}>Valider</button>
                            </div>
                        </div>
                        {showConfirmModal && (
                            <ConfirmModal
                                message="Êtes-vous sûr de vouloir valider la commande ?"
                                onConfirm={handleConfirmOrder}
                                onCancel={handleCancelOrder}
                            />
                        )}
            </div>
        </div>
        
    );
};

export default CheckoutPage;